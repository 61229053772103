.tabela {
  width: 80%;
  border-collapse: collapse;
}

.tabela th, .tabela td {
  border: 1px solid #ddd;
  padding: 8px;
  text-align: center; /* Centraliza horizontalmente */
  vertical-align: middle; /* Centraliza verticalmente */
}

.tabela th {
  background-color: #67bbff;
  text-align: center;
}

.tabela tbody tr:nth-child(even) {
  background-color: #abe2f8;
}

.tabela tbody tr:nth-child(odd) {
  background-color: #ffffff;
}

.tabela tbody tr:hover {
  background-color: #75f78b;
}

.tabela tbody td {
  justify-content: center; /* Centraliza horizontalmente */
  align-items: center; /* Centraliza verticalmente */
}

.btnEditar {
  background-color: #4CAF50; /* Verde para Editar */
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}

.btnExcluir {
  background-color: #f44336; /* Vermelho para Excluir */
  color: white;
  padding: 8px 12px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  margin-right: 8px;
}

