.etapasContainer {
    justify-content: center;
    text-align: center;
}

.provasContainer {
    display: flex;
    /*flex-direction: column; /* Organiza as checkboxes em coluna */
    gap: 8px;
    padding: 16px;
    background-color: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 4px 12px rgba(0, 0, 0, 0.1);
    justify-content: center;
    text-align: center;
}

.provasContainer label {
    display: flex;
    align-items: center;
    padding: 8px 12px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
    font-family: Arial, sans-serif;
    font-size: 14px;
}

/* Alternância de cores */
.provasContainer label:nth-child(odd) {
    background-color: #7eeaf8;
    /* Cor para linhas ímpares */
}

.provasContainer label:nth-child(even) {
    background-color: #ffffff;
    /* Cor para linhas pares */
}

/* Checkbox personalizado */
.provasContainer input[type="checkbox"] {
    margin-right: 8px;
    width: 16px;
    height: 16px;
    cursor: pointer;
}

/* Hover para um efeito visual */
.provasContainer label:hover {
    background-color: #cce7e8;
    /* Cor ao passar o mouse */
}

.provasContainer h3 {
    color: black;
}


/* Estilos dos botões */
.button-group {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 20px;
}

.btnComponente {
    background: linear-gradient(135deg, #6c63ff, #5a54e5);
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    flex: 1;
    margin-right: 10px;
}

.btnComponente:hover {
    background: linear-gradient(135deg, #5a54e5, #6c63ff);
}

.btnVoltar {
    background: linear-gradient(135deg, #ff7e5f, #ff6b4f);
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    flex: 1;
}

.btnVoltar:hover {
    background: linear-gradient(135deg, #ff6b4f, #ff7e5f);
}

button:disabled, .btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
}


.cadastroContainer { /* CAMPO DE FORMULARIO COMPLETO */
    width: 70%;
    margin: 0 auto;
    padding: 20px;
    background-color: #f9f9f9;
    /* Fundo claro */
    border: 1px solid #ddd;
    /* Borda suave */
    border-radius: 8px;
    /* Cantos arredondados */
    box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
    /* Sombra suave */
    display: flex;
    flex-direction: column;
    align-items: center;
}

.radioRaias {
    display: flex;
    flex-direction: row;
    gap: 10px;
}