.campoTexto {
  align-items: center;
}

.campoTexto label {
  flex: 1;
  /* Ocupa todo o espaço disponível */
  font-size: 16px;
}

.campoTexto input {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  font-size: 16px;
  outline: none;
}

input:focus {
  /* Estilos para o input quando focado */
  border-color: #999;
}