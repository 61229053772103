h1, h2 {
    text-align: center;
    color: #343a40;
}

/* Estilos do formulário */
.formulario {
    width: 100%;
    max-width: 600px;
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

form {
    display: flex;
    flex-direction: column;
    gap: 16px;
}

/* Estilos dos inputs */
.inputTexto {
    display: flex;
    flex-direction: column;
    margin-bottom: 16px;
}

.inputTexto label {
    margin-bottom: 8px;
    font-weight: bold;
}

.inputTexto input, .inputTexto select, .inputTexto textarea {
    padding: 12px;
    border: 1px solid #ced4da;
    border-radius: 8px;
    font-size: 16px;
    width: 100%;
    box-sizing: border-box;
    transition: border-color 0.3s ease;
}

.inputTexto input:focus, .inputTexto select:focus, .inputTexto textarea:focus {
    border-color: #6c63ff;
    outline: none;
}

/* Estilos dos botões */
button, .btn {
    background: linear-gradient(135deg, #6c63ff, #5a54e5);
    color: white;
    padding: 12px 20px;
    font-size: 16px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: background 0.3s ease;
    margin-top: 16px;
    width: 150px; /* Tamanho padronizado */
    align-self: center; /* Centraliza o botão */
}

button:hover, .btn:hover {
    background: linear-gradient(135deg, #5a54e5, #6c63ff);
}

button:disabled, .btn:disabled {
    background: #cccccc;
    cursor: not-allowed;
}

/* Estilos do Select */
select {
    width: 300px;
    padding: 12px;
    margin: 10px 0;
    border: 2px solid #ccc;
    border-radius: 8px;
    font-size: 16px;
    background-color: #f0f0f0;
    transition: border-color 0.3s, box-shadow 0.3s;
    appearance: none; /* Remove default arrow */
    background-image: url('data:image/svg+xml;utf8,<svg fill="%23007bff" height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg"><path d="M7 10l5 5 5-5z"/></svg>'); /* Custom arrow */
    background-repeat: no-repeat;
    background-position: right 10px center;
    background-size: 16px 1
}
