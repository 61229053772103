.container {
    flex-direction: column;
}

.iconesSociais {
    text-decoration: none;
    list-style: none;
    display: flex;
}

img {
    height: 40px;
    width: 40px;
}