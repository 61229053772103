.inscricaoContainer {
    text-align: center;
}

.centralizado {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.listaSuspensa:focus {
    border-color: #007bff;
    box-shadow: 0 0 5px rgba(0, 123, 255, 0.5);
    outline: none;
}

.provasLegenda {
    background-color: #f9f9f9;
    border: 1px solid #ddd;
    border-radius: 8px;
    margin: 20px auto;
    max-width: 300px;
    padding: 10px;
    text-align: center;
}

.provasLegenda h3 {
    margin-bottom: 10px;
    font-size: 1.2em;
    color: #333;
}

.provasLegenda ul {
    list-style-type: none;
    padding: 0;
}

.provasLegenda li {
    padding: 5px 0;
    border-bottom: 1px solid #eee;
    font-size: 0.9em;
    color: #555;
}

.provasLegenda li:last-child {
    border-bottom: none;
}

/* button {
    padding: 12px 24px;
    font-size: 18px;
    border: none;
    border-radius: 8px;
    background-color: #007bff;
    color: white;
    cursor: pointer;
    transition: background-color 0.3s, transform 0.3s;
}

button:hover {
    background-color: #0056b3;
    transform: scale(1.05);
}

button:active {
    background-color: #004494;
}
 */

.inscricaoRevezamento {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 40%;
    margin: 0 auto;
}

.revezamentoContainer {
    background-color: #f0f8ff; /* Light blue background */
    padding: 20px;
    border-radius: 10px;
    margin: 20px 0;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.revezamentoContainer h3 {
    color: #333;
    font-size: 1.5em;
    margin-bottom: 15px;
}

.inscricaoRevezamento span {
    display: block;
    font-size: 1.1em;
    color: #007bff;
    margin-bottom: 10px;
    font-weight: bold; 
}

@media (max-width: 768px) {
    .inscricaoContainer {
        padding: 10px;
    }

    .centralizado {
        width: 100%;
        display: block; /* Remove flex display for mobile */
    }

    table {
        width: 100%;
        border-collapse: collapse;
    }

    th, td {
        padding: 8px;
        text-align: left;
        border: 1px solid #ddd;
    }

    th {
        background-color: #f2f2f2;
    }

    .provasLegenda {
        max-width: 100%;
    }

    .revezamentoContainer {
        width: 100%;
        padding: 10px; 
        margin: 10px 0; 
    }

    .inscricaoRevezamento {
        width: 100%;
    }
}