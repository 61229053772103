.rodape {
    background-color: #003366;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 20px;
    text-align: center;
}

.rodape p {
    max-width: 30%;
    color: white;
}

@media (max-width: 600px) {
    .rodape {
        font-size: 14px;
        padding: 10px;
    }

    .rodape p {
        margin: 10px 0;
    }

    .rodape h3, .rodape h4 {
        font-size: 16px;
    }
}