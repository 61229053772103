.usuarios {
    padding: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.form-container {
    background-color: white;
    padding: 20px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    margin-top: 20px;
    width: 100%;
    max-width: 600px;
}

.form-container h2 {
    margin-bottom: 20px;
    color: #333;
}

.form-container label {
    display: block;
    text-align: left;
    margin-bottom: 8px;
    color: #555;
}

.form-container input,
.form-container select {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.form-container input:focus,
.form-container select:focus {
    border-color: #007bff;
    outline: none;
}

.form-container h3{
    margin-bottom: 10px;
    color: #333;
}

.form-container .checkbox-group {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 20px;
}

.form-container .checkbox-group label {
    margin-right: 10px;
    color: #333; /* Ajustar a cor para que fique visível */
    display: flex;
    align-items: center;
}

.form-container .checkbox-group input[type="checkbox"] {
    margin-right: 5px;
}

.form-container .button-group {
    display: flex;
    justify-content: flex-end;
}

.form-container .button-group button {
    padding: 10px 20px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    margin-left: 10px;
}

.form-container .button-group button:hover {
    background-color: #0056b3;
}
