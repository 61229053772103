.containerTabela {
    width: 100%;
    overflow-x: auto;
}

table {
    width: 100%;
    border-collapse: collapse;
}

th, td {
    padding: 8px;
    text-align: left;
    border: 1px solid #ddd;
}

th {
    background-color: #f2f2f2;
    white-space: normal; /* Permite quebra de linha */
    word-wrap: break-word;
    text-align: center; /* Melhor alinhamento para quebras */
    max-width: 80px; /* Ajuste conforme necessário */
    font-size: 14px; /* Opcional: reduz tamanho da fonte */
}

@media (max-width: 768px) {
    .containerTabela {
        overflow-x: scroll; /* Permite rolagem horizontal */
        -webkit-overflow-scrolling: touch; /* Suaviza a rolagem em dispositivos móveis */
    }

    th, td {
        padding: 6px;
    }

    table {
        min-width: 600px; /* Define uma largura mínima para a tabela */
    }
}
