.container {
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    justify-content: center;
}

.container h1 {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    display: block;
}

.cards {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
}

.cardsContainer {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 16px;/* distancia entre os cards */
    justify-content: center; 
}

