.checkboxContainer {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  
  input[type="checkbox"] {
    width: 20px;
    height: 20px;
    cursor: pointer;
    appearance: none;
    -webkit-appearance: none;
    background-color: #f0f0f0;
    border: 2px solid #000000;
    border-radius: 4px;
    position: relative;
    margin: 0;
    outline: none;
    transition: background-color 0.2s, border-color 0.2s;
  }
  
  input[type="checkbox"]:checked {
    background-color: #4caf50;
    border-color: #4caf50;
  }
  
  input[type="checkbox"]:checked::before {
    content: '\2714'; /* Unicode character for checkmark */
    font-size: 14px;
    color: white;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
  
  input[type="checkbox"]:disabled {
    background-color: #e6e6e6;
    border-color: #d6d6d6;
    cursor: not-allowed;
  }
  
  input[type="checkbox"]:disabled::before {
    color: #a6a6a6;
  }
  