.formularioContainer {
  width: 70%;
  margin: 0 auto;
  padding: 20px;
  background-color: #f9f9f9;
  /* Fundo claro */
  border: 1px solid #ddd;
  /* Borda suave */
  border-radius: 8px;
  /* Cantos arredondados */
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  /* Sombra suave */
  display: flex;
  flex-direction: column;
  align-items: center;
}

.centralizarBotao {
    display: flex;
    justify-content: center;
    margin: 20px 0; /* Espaçamento acima e abaixo */
}
