body {
    background-image: linear-gradient(90deg, #002F52 35%, #326589 165%);
    width: 100%;
    height: 100%;
}

.container {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 20px;
    padding: 20px;
}

.tile {
    background-color: #ffffff;
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 20px;
    text-align: center;
    transition: transform 0.2s, box-shadow 0.2s;
    width: 200px;
}

.tile:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

.tile h2 {
    font-size: 1.5em;
    margin-bottom: 10px;
    color: #333;
}

.tile p {
    font-size: 1em;
    color: #666;
}