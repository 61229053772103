.login-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: #f0f2f5;
}

.login-box {
    background-color: white;
    padding: 40px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    text-align: center;
    max-width: 400px;
    width: 100%;
}

.login-box h1 {
    margin-bottom: 20px;
    color: #333;
}

.login-box label {
    display: block;
    text-align: left;
    margin-bottom: 8px;
    color: #555;
}

.login-box input {
    width: 100%;
    padding: 10px;
    margin-bottom: 20px;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-sizing: border-box;
}

.login-box input:focus {
    border-color: #007bff;
    outline: none;
}

.login-box .error-message {
    color: red;
    margin-bottom: 20px;
}

.login-box .login-button {
    width: 100%;
    padding: 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-box .login-button:hover {
    background-color: #0056b3;
}
